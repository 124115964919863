import { BaseDto } from './base-dto';

export class patologia extends BaseDto {
    public nombre_patologia !: string;
    public descripcion_patologia !: string;
    public puede_masticar !: boolean;
    public gr_prot_dia_min !: number;
    public gr_hc_dia_min !: number;
    public macro_hc !: number;
    public macro_prot !: number;
    public macro_gra !: number;
    public sistema !: boolean;
    public tipo_de_patologia !: number;
    public nombre_tipo_patologia !: string;
 } 
