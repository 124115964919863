import { store } from '@/store/store';
import { patologia } from '@/shared/dtos/patologia';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { TipoPatologia } from '@/utils/TipoPatologia';

@Module({
    namespaced: true,
    name: 'patologiaModule',
    store,
    dynamic: true
})
class patologiaModule extends VuexModule {
    public patologias: patologia[] = [];
    public patologia: patologia = new patologia();
    public patologiasEstadosFisiologicos: patologia[] = [];
    public patologiasEstilosDeAlimentacion: patologia[] = [];
    public patologiasEstadosTiposDeDeporte: patologia[] = [];
    public patologiasObjetivoTratamiento: patologia[] = [];
    public titulo: string = "";

    @Action({ commit: 'onGetpatologias' })
    public async getpatologias() {
        return await ssmHttpService.get(API.patologia);
    }

    @Action({ commit: 'onGetpatologia' })
    public async getpatologia(id: any) {
        return await ssmHttpService.get(API.patologia + '/' + id);
    }

    @Action({ commit: 'onGetpatologias' })
    public async getpatologiastopaciente(id: number) {
        return await ssmHttpService.get(API.patologia + '/AsingPatos/' + id);
    }
    @Action({ commit: 'onGetpatologiasTipo' })
    public async getpatologiasFromTipo(id: TipoPatologia) {
        return { tipo: id, patos: await ssmHttpService.get(API.patologia + '/FromTipo/' + id) };
    }

    @Action({ commit: 'onGetpatologiasTipo' })
    public async getpatologiasFromCahe(id: TipoPatologia) {
        let arr = await this.data_cahe_from_tipo(id);
        if (arr.length === 0) {
            return { tipo: id, patos: await ssmHttpService.get(API.patologia + '/FromTipo/' + id) };
        } else {
            return { tipo: id, patos: arr };
        }
    }


    @Action
    public async guardarpatologia(patologia: patologia) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.patologia, patologia.toJson());
        this.getpatologias();
    }

    @Action
    public async modificarpatologia(patologia: patologia) {
        await ssmHttpService.put(API.patologia, patologia);
        this.getpatologias();
    }

    @Action
    public async eliminarpatologia(patologia: patologia) {
        await ssmHttpService.delete(API.patologia + '/' + patologia.id, null, false);
        this.getpatologias();
    }

    @Action({ commit: 'onGetTituloPatologiasFromTipo' })
    public async gettitulopatologiasFromTipo(id: TipoPatologia) {
        switch (id) {
            case TipoPatologia.EstadosTiposDeDeporte:
                return "Tipos de deporte";
            case TipoPatologia.Estadosfisiologicos:
                return "Estados fisiológicos";

            case TipoPatologia.EstilosDeAlimentacion:
                return "Estilos de alimentación";

            case TipoPatologia.ObjetivoTratamiento:
                return "Objetivos tratamiento";

            case TipoPatologia.Patologías:
                return "Patologías";

        }
    }
    @Action
    public data_cahe_from_tipo(id: TipoPatologia) {
        switch (id) {
            case TipoPatologia.EstadosTiposDeDeporte:
                return this.patologiasEstadosTiposDeDeporte;

            case TipoPatologia.Estadosfisiologicos:
                return this.patologiasEstadosFisiologicos;

            case TipoPatologia.EstilosDeAlimentacion:
                return this.patologiasEstilosDeAlimentacion;

            case TipoPatologia.ObjetivoTratamiento:
                return this.patologiasObjetivoTratamiento;

            case TipoPatologia.Patologías:
                return this.patologias;

        }
    }
    @Mutation
    public onGetpatologias(res: patologia[]) {
        this.patologias = res ? res.map((x) => new patologia(x)) : [];
    }

    @Mutation
    public onGetTituloPatologiasFromTipo(res: string) {
        this.titulo = res;
    }
    @Mutation
    public onGetpatologia(res: patologia) {
        this.patologia = new patologia(res);
    }
    @Mutation
    public onGetpatologiasTipo(res: any) {
        //lo hacemos así y podremos hacer una carga de todas las
        //patologías asincrona en el asistente de la dieta
        // de esta manaera lo cargaremos y luego buscaremos la variable indicada 
        //para cada tipo de pantalla
        switch (res.tipo) {
            case TipoPatologia.EstadosTiposDeDeporte:
                this.patologiasEstadosTiposDeDeporte = res.patos;
                break;
            case TipoPatologia.Estadosfisiologicos:
                this.patologiasEstadosFisiologicos = res.patos;
                break;
            case TipoPatologia.EstilosDeAlimentacion:
                this.patologiasEstilosDeAlimentacion = res.patos;
                break;
            case TipoPatologia.ObjetivoTratamiento:
                this.patologiasObjetivoTratamiento = res.patos;
                break;
            case TipoPatologia.Patologías:
                this.patologias = res.patos;
                break;
        }
    }

}
export default getModule(patologiaModule);