









































import { Component, Vue, Prop } from "vue-property-decorator";
import { patologia } from "@/shared/dtos/patologia";
import patologiaModule from "@/store/modules/patologia-module";
import { TipoPatologia } from "@/utils/TipoPatologia";
@Component({})
export default class PatologiasLista extends Vue {
  @Prop({ default: 1 }) tipo!: number;
  public search: string = "";

  public created() {
    //this.$route.meta;
  }
  public get patologias() {
    switch (this.tipo) {
      case TipoPatologia.EstadosTiposDeDeporte:
        return patologiaModule.patologiasEstadosTiposDeDeporte;
        break;
      case TipoPatologia.Estadosfisiologicos:
        return patologiaModule.patologiasEstadosFisiologicos;
        break;
      case TipoPatologia.EstilosDeAlimentacion:
        return patologiaModule.patologiasEstilosDeAlimentacion;
        break;
      case TipoPatologia.ObjetivoTratamiento:
        return patologiaModule.patologiasObjetivoTratamiento;
        break;
      case TipoPatologia.Patologías:
        return patologiaModule.patologias;
        break;
    }
    return patologiaModule.patologias;
  }

  public get title() {
    // se hace desde aqui por que como los parametros vienen desde el router, solo pasa una vez por el created
    //pero por aqui siempre pasa

    patologiaModule.gettitulopatologiasFromTipo(this.tipo);
    patologiaModule.getpatologiasFromCahe(this.tipo);

    return patologiaModule.titulo;
  }

  public get headers() {
    if (this.tipo !== TipoPatologia.ObjetivoTratamiento) {
      return [
        {
          text: "Nombre",
          align: "start",
          value: "nombre_patologia"
        },
        { text: "Mastica", value: "puede_masticar" },
        { text: "% Prot. dieta", value: "macro_prot" },
        { text: "% Hc. dieta", value: "macro_hc" },
        { text: "% Gr. dieta", value: "macro_gra" },
        { text: "Tipo", value: "nombre_tipo_patologia" }
      ];
    } else {
      return [
        {
          text: "Nombre",
          align: "start",
          value: "nombre_patologia"
        },
        { text: "Tipo", value: "nombre_tipo_patologia" }
      ];
    }
  }
}
